<template>
  <div v-if="showModal" id="aceptar_condiciones" class="modal fade show">
    <div class="modal-dialog-broad" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title">{{$t('modalLastConsent.title')}}</h5>
          <div class="divider-wrap col-12">
            <div class="divider-middle mx-auto"></div>
          </div>
          <p class="modal-text mx-auto">{{$t('modalLastConsent.subtitle')}}</p>
          <img src="@/assets/img/icon-agreement.svg" alt="" class="mx-auto icon-modal">
          <div class="form-check-container">
            <span class="form-check-label" v-html="$t('modalLastConsent.consentZero')"></span>
            <!-- <div v-for="(consent, index) in consents" :key="index" class="form-check">
              <label class="container form-check-label">
                <span v-if="$i18n.locale === 'en'">{{$t('modalLastConsent.consentOne')}}</span>
                <span v-if="$i18n.locale === 'es'">{{$t('modalLastConsent.consentOne')}}</span> - {{consent.name}}
                <input v-model="consent.accepted" type="checkbox">
                <span class="checkmark"></span>
              </label>
            </div> -->
            <div class="form-check">
              <label class="container form-check-label">{{$t('modalLastConsent.consentOne')}}
                <input v-model="consentOne.accepted" type="checkbox">
                <span class="checkmark"></span>
              </label>
              <div class="tooltip-error" v-if="consentOneRequired"> 
                <div class="arrow"></div>
                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
              </div>
            </div>
            <div class="form-check">
              <label class="container form-check-label">{{$t('modalLastConsent.consentTwo')}}
                <input v-model="consentTwo.accepted" type="checkbox">
                <span class="checkmark"></span>
              </label>
              <div class="tooltip-error" v-if="consentTwoRequired"> 
                <div class="arrow"></div>
                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
              </div>
            </div>
          </div>
          <div class="col text-center"> 
            <button @click="submitConsent()" type="button" class="btn btn-primary rounded-pill btn-modal">{{$t('modalLastConsent.button')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalExpireToken v-if="expireToken"/>
  <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />


</template>

<script>

import ModalExpireToken from '@/components/ModalExpireToken.vue'
import ModalServiceError from '@/components/ModalServiceError.vue'
import { mapState } from 'vuex'

export default {
  name: 'modalLastConsent',
  components: {
    ModalExpireToken,
    ModalServiceError
  },
  data() {
    return {
      showModal: false,
      consentOne: {
        name: null,
        id: null,
        accepted: false
      },
      consentTwo: {
        name: null,
        id: null,
        accepted: false
      },
      consentOneRequired: false,
      consentTwoRequired: false,
      expireToken: false,
      serviceError: false,
      serviceErrorMsg: null
    }
  },
  computed: {
    ...mapState([
      'enviroments',
      'idAdmission',
      'msalConfig'
    ]),
    account() {
      if(this.msalConfig.account!=null)
      return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined
      else
      return undefined;
    },
    accessToken() {
      if(this.msalConfig.accessToken!=null)
      return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined
      else
      return undefined;
    },
    validateConsentOne() {
      return this.consentOne.accepted;
    },
    validateConsentTwo() {
      return this.consentTwo.accepted;
    }
  },
  watch: {
    validateConsentOne(newValue) {
      if(newValue) {
        this.consentOneRequired = false;
      } else {
        this.consentOneRequired = true;
      }
    },
    validateConsentTwo(newValue) {
      if(newValue) {
        this.consentTwoRequired = false;
      } else {
        this.consentTwoRequired = true;
      }
    }
  },
  mounted() {
    if(this.accessToken&&this.idAdmission)
      this.getConsent();
  },
  methods: {
    getConsent() {

      this.axios.get(`${this.enviroments.urlDev}/Consent?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {

       if (response.data.status === 1) {
          this.serviceError = true;
          this.serviceErrorMsg =  response.data.message;
        } else if(response.data.status === 2) {
          this.expireToken = true;
        }
        else if(response.data.status === 0){

          document.body.style.overflowY = "auto";

          if(response.data.consent === null) {
            document.body.style.overflowY = "hidden";
            this.showModal = true;
            this.setConsent({}, 1);
          
          } else {

            response.data.consent.find(consent => {

              if(consent.name === "Data Integrity Declaration") this.consentOne = consent
              if(consent.name === "Use of Data Processing Consent") this.consentTwo = consent

              if((consent.name === "Data Integrity Declaration" && consent.accepted === false) ||
                (consent.name === "Use of Data Processing Consent" && consent.accepted === false)) {
                document.body.style.overflowY = "hidden";
                this.showModal = true;
              }
            })
          }

        }

      })
      .catch( error => {
        console.log(error);
        this.serviceError = true;
        this.serviceErrorMsg =  `ERROR:: LAST CONSENT get Consents: ${error}`;
      });      
    },
    modalErrorClose()
    {this.serviceError=false},
    setConsent(params, step) {

      let data = {}
      if (step === 2)
      {      
         data = {consent: params}
      }
      
      this.axios.post(`${this.enviroments.urlDev}/Consent?token=${this.accessToken}&id_admision=${this.idAdmission}`, data).then(response => {
       
        if (response.data.status === 1) {
          this.serviceError = true;
          this.serviceErrorMsg =  response.data.message;
        } else if(response.data.status === 2) {
          this.expireToken = true;
        }
        else{       
          if((response.data.consent !== null) && (response.data.consent.length > 1) && (step === 1)) this.getConsent();
        }
      })
      .catch( error => {
        console.log(error);
        this.serviceError = true;
        this.serviceErrorMsg =  `ERROR:: LAST CONSENT set Consents: ${error}`;
      });
    },
    submitConsent() {
      if (!this.consentOne.accepted || !this.consentTwo.accepted) {
        this.consentOneRequired = true;
        this.consentTwoRequired = true;
        return
      }
      // this.consentOne.email = email;
      // this.consentTwo.email = email;
      this.setConsent(this.consentOne, 2);
      this.setConsent(this.consentTwo, 2);
      document.body.style.overflowY = "auto";
      this.showModal = false;
    }
  }
}
</script>

<style>
.fade:not(.show) {
  display: none;
}
.fade.show {
  display: block;
}
</style>