<template>
  <div>
    <ModalLastConsent/>
    <HeaderBar/>
    <Header steps="true" stepNumb="5"/>
    <Steps step_a="5" completed="[true, true, true, true]"/>

    <div v-if="!paymentOK" class="background-grey background-form"> 
      <div class="container">
        <form :action="urlTpv" ref="form1" method="POST">
          <input type="hidden" name="VendorCode" v-model="transactionData.vendorCode">
          <input type="hidden" name="TransactionData.ProductCode" v-model="transactionData.productCode">
          <input type="hidden" name="TransactionData.OrderID" v-model="transactionData.orderID">
          <input type="hidden" name="TransactionData.TotalAmount" v-model="transactionData.totalAmount">
          <input type="hidden" name="TransactionData.TransactionDate" v-model="transactionData.transactionDate">
          <input type="hidden" name="TransactionData.TransactionHour" v-model="transactionData.transactionHour">
          <input type="hidden" name="Token" v-model="token">
          <input type="hidden" name="FormLang" v-model="formLang">
          <input type='hidden' name='ProcessorCode' v-model='processorCode'>
          <input type="hidden" name="Customer.Id">
          <input type="hidden" name="Customer.FirstName" v-model="customer.firstName">
          <input type="hidden" name="Customer.LastName" v-model="customer.lastName">
          <input type="hidden" name="Customer.Email" v-model="customer.email">
          <input type="hidden" name="Customer.Phone" v-model="customer.phone">
          <input type="hidden" name="BillingAddress.StreetName" v-model="customer.streetName">
          <input type="hidden" name="BillingAddress.ExtendedAddress " v-model="customer.extendedAddress">
          <input type="hidden" name="BillingAddress.Locality" value="">
          <input type="hidden" name="BillingAddress.Region" value="">
          <input type="hidden" name="BillingAddress.PostalCode" v-model="customer.zip">
          <input type="hidden" name="BillingAddress.CountryCodeIsoAlpha2" value="">
          <input type='hidden' name='TaskParam.IdRegAActualizar' value=''>
          <input type='hidden' name='TaskParam.IdRegParaSacarDatosDeMail' value=''>

          <div class="bloque-informacion-basica form-paso2" id="paso2">
            <div class="row align-items-start">
              <div class="informacion-basica-titulo  col-12">
                <h2 class="informacion-basica-texto">{{$t('PaymentFee.title')}}</h2>	  
                <div class="divider-wrap ">
                  <div class="divider-middle mx-auto"></div>
                </div>
              </div>
              <div class="bloque-info-lines no-form "> 
                <p v-if="!exento" class="bloque-info col-12 text-center" v-html="$t('PaymentFee.description')"></p>
                <p v-if="exento" class="bloque-info col-12 text-center" v-html="$t('PaymentFee.descriptionExent')"></p>
              </div>
              <div class="col-12 offset-md-1 col-md-11 offset-md-1 offset-xl-2 col-xl-9"> 
                <button @click="backStep()" type="button" class="rounded-pill btn-lg btn-primary mb-2 form-btn-back col-md-5">{{$t('PaymentFee.buttons.back')}}</button>
                <button v-if="!exento" @click="launchPayment()" type="button" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit offset-md-1 offset-xl-1 col-md-5">{{$t('PaymentFee.buttons.save')}}</button>
                <button v-if="exento" @click="launchExento()" type="button" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit offset-md-1 offset-xl-1 col-md-5">{{$t('PaymentFee.buttons.save')}}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div v-if="paymentKO" id="payment_ko" style="display:block;" class="modal fade show">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">{{$t('modalPaymentKO.title')}}</h5>
            <div class="divider-wrap col-12">
              <div class="divider-middle mx-auto"></div>
            </div>
            <p class="modal-text mx-auto">{{$t('modalPaymentKO.subtitle')}}</p>
            <img src="@/assets/img/icon-error.svg" alt="" class="mx-auto icon-modal">
            <div class="col text-center"> 
              <button @click="closeModalPaymentKO(false)" type="submit" class="btn btn-secondary btn-modal rounded-pill col-md-5">{{$t('modalPaymentKO.button')}}</button>
              <button @click="closeModalPaymentKO(true)" type="submit" class="btn btn-primary btn-modal rounded-pill col-md-5">{{$t('modalPaymentKO.button1')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div v-if="paymentOK" class="background-grey background-form"> 
      <div class="container">
          <div class="bloque-informacion-basica form-paso2" id="paso2">
            <div class="row align-items-start">
              <div class="informacion-basica-titulo  col-12">
                <h2 class="informacion-basica-texto">{{$t('ConfirmationProcess.title')}}</h2>	  
                <div class="divider-wrap ">
                  <div class="divider-middle mx-auto"></div>
                </div>
              </div>
              <div class="bloque-info-lines no-form "> 
                <p class="bloque-info col-12 text-center" v-html="$t('ConfirmationProcess.descriptionOne')"></p>
                <p class="bloque-info col-12 text-center" v-html="$t('ConfirmationProcess.descriptionTwo')"></p>
              </div>
            </div>
          </div>
      </div>
    </div>



    <ModalExpireToken v-if="expireToken"/>
    <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />
    <Footer/>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import ModalLastConsent from '@/components/ModalLastConsent.vue'
import ModalExpireToken from '@/components/ModalExpireToken.vue'
import ModalServiceError from '@/components/ModalServiceError.vue'
import HeaderBar from '@/components/HeaderBar.vue'
import Header from '@/components/Header.vue'
import Steps from '@/components/Steps.vue'
import Footer from '@/components/Footer.vue'
import moment from 'moment'
import { useRoute } from 'vue-router'

export default {
  name: 'PaymentFee',
  components: {
    HeaderBar,
    Header,
    Steps,
    Footer,
    ModalExpireToken,
    ModalServiceError,
    ModalLastConsent
  },
  mounted() {
//    this.account = {'username' : 'jap.leon@boream.com'}
//    this.accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2lldW5pdmVyc2l0eWIyY3Rlc3QuYjJjbG9naW4uY29tLzQzMWEyY2MyLTkzNTAtNDczMy04Y2IwLWQyNjk0MzUzNWI3Mi92Mi4wLyIsImV4cCI6MTYyODc3MDg2NywibmJmIjoxNjI4NzY4MTY3LCJhdWQiOiIzZTlkMjU5Mi1lYmI0LTQ5ODEtODg1Ny1hN2YyY2MyMmRhOTciLCJuYW1lIjoiamFwIGxlb24iLCJpZHAiOiJnb29nbGUuY29tIiwic3ViIjoiN2QyZTA2ZGItZTdlNS00MjMzLTlkNTMtMzdhNGExYzEyOTcyIiwiZW1haWxzIjpbImphcC5sZW9uQGJvcmVhbS5jb20iXSwidGZwIjoiQjJDXzFfR2xvYmFsQ29sbGVnZV9zaWduaW5zaWdudXAiLCJub25jZSI6Ijk1ZDU3MDg4LWY3YmYtNGNjYy1hNTQxLWIzMmViMWZjYTY5YSIsInNjcCI6ImRlbW8ud3JpdGUiLCJhenAiOiIzZTlkMjU5Mi1lYmI0LTQ5ODEtODg1Ny1hN2YyY2MyMmRhOTciLCJ2ZXIiOiIxLjAiLCJpYXQiOjE2Mjg3NjgxNjd9.kC03Fr4lKYpnIiJKauuSXs8aI4mFX1RaesBXF5xx3p4sxC72f2e2Ga4RxM_aawef5HWfWVZDOgOK13E0vL4GT-N5g_YwU2O7Nbk6vQAtcP5dVCrqgJYxPL5Rb9HPrGYRm6O50jbhQu92SYdKTtg9HVp77Tjacc2nyhKSJYbQPevv0vdGr2afjHkRN5LweQishZSx-ZHzLqELAFpzJhoySJUJmxKx_2J9Ic3H6rxsY50VE0bOw8etKfLrprHbB6diAcgoxt2OVtz6B6ordW-FDntLHWyH0NZQPW46YLSAB-2bsZZM59a1qrtqz2qxCFgAujdLok3dGchYCISdz8ffuA"
//    this.idAdmission = '2a544797-8dfa-eb11-94ef-0022489ccabe'

    window.scrollTo(0,0); 
    document.body.style.overflowY = "auto";

    const route = useRoute();
    console.log(route.query);
    this.paymentKO = route.query.status == 'KO' ? true : false;
    this.paymentOK = route.query.status == 'OK' ? true : false;
    if (this.paymentOK) 
    { 
        this.step.firstStep = false;
        this.step.step5OK = true;
    }
    else{
        this.step.firstStep = true;
        this.step.step5OK = false;
    }

    if (this.paymentKO || this.paymentOK) 
    { 

        if (sessionStorage.getItem('accessToken') != null) {
                this.$store.commit('msalConfig/setAccessToken', sessionStorage.getItem('accessToken'));
          }

        if (sessionStorage.getItem('idAdmission') != null) {
                this.$store.commit('setIdAdmission', sessionStorage.getItem('idAdmission'));
          }

    }
    else if (!this.paymentKO && !this.paymentOK && this.msalConfig.accessToken!=null) 
      {this.getPayment();}

  },
  data() {
    return {
      urlTpv: 'https://vpcweb-pre.ie.edu/IEPaymentsWeb2/Transaction/BeginBraintreeTransaction',
      transactionData: {
        vendorCode: null,
        productCode: null,
        vendorPassword: null,
        transactionDate: moment(new Date()).format('DD/MM/YYYY'),
        transactionHour: moment(new Date()).format('HH:MM'),
        orderID: null,
        totalAmount: 0
      },
      customer: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        streetName: null,
        extendedAddress: null,
        zip: null,
      },
      formLang: null,
      token: null,
      processorCode: null,
      expireToken: false,
      serviceError: false,
      serviceErrorMsg: null,
      paymentOK: false,
      paymentKO: false,
      exento: false
    }
  },
  computed: {
    ...mapState([
      'step',
      'enviroments',
      'idAdmission',
      'msalConfig'
    ]),
    account() {
      if(this.msalConfig.account!=null)
      return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined
      else
      return null;
    },
    accessToken() {
      if(this.msalConfig.accessToken!=null)
      return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined
      else
      return null;
    }
  },
  methods: {

    getPayment() {
      this.axios.post(`${this.enviroments.urlDev}/PaymentInfo?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {
        
        if (response.data.status === 1) {
          this.serviceError = true;
          this.serviceErrorMsg =  response.data.message;
        } else if(response.data.status === 2) {
          this.expireToken = true;
        }
        else{

          const paymentInfo = response.data;
          this.urlTpv = paymentInfo.urlPasarela;
          this.transactionData = {
            vendorCode: paymentInfo.vendorCode,
            productCode: paymentInfo.productCode,
            vendorPassword: '',
            transactionDate: paymentInfo.transactionDate,
            transactionHour: paymentInfo.transactionHour,
            orderID: paymentInfo.admission_OrderNumber,
            totalAmount: paymentInfo.admission_AmountPas
          }

          this.customer = {
            id: paymentInfo.contact_Id,
            firstName: paymentInfo.contact_FirstName,
            lastName: paymentInfo.contact_LastName,
            email: paymentInfo.contact_Email,
            phone: paymentInfo.contact_Phone,
            streetName: paymentInfo.contact_Address_Street,
            extendedAddress: paymentInfo.extendedAddress,
            zip: paymentInfo.contact_Address_Zip
          }

          //this.formLang = paymentInfo.formLang;
          this.formLang = this.$i18n.locale;
          this.token = paymentInfo.token;
          this.processorCode = paymentInfo.processorCode;
          this.exento = paymentInfo.exento_pago;
        
        }

      })
      .catch(function (error) {
        console.log(error);
      });
    },
    launchPayment(){

      if (!this.step.step1PhotoCompleted)
      {
        this.serviceError = true;
        this.serviceErrorMsg =  this.$t('errors.step1PhotoCompleted');          
      }
      else
      {


          if(this.accessToken&&this.idAdmission)
          {
            this.axios.post(`${this.enviroments.urlDev}/PaymentInfo?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {
            if (response.data.status === 1) {
              this.serviceError = true;
              this.serviceErrorMsg =  response.data.message;
            } else if(response.data.status === 2) {
              this.expireToken = true;
            }
            else{
              if (response.data.step_admission === "step6")
              {
                this.$router.push('confirmation-process');
                this.step.firstStep = false;
              }
              else{
                this.$refs.form1.submit()
              }
            }
            })
            .catch(function (error) {
              console.log(error);
            });
          }
          else
          {this.paymentKO=true}

      }

    },

    launchExento(){
      
      if (!this.step.step1PhotoCompleted)
      {
        this.serviceError = true;
        this.serviceErrorMsg =  this.$t('errors.step1PhotoCompleted');          
      }
      else
      {


          if(this.accessToken&&this.idAdmission)
          {

            this.axios.post(`${this.enviroments.urlDev}/ExentPayment?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {
            if (response.data.status === 1) {
              this.serviceError = true;
              this.serviceErrorMsg =  response.data.message;
            } else if(response.data.status === 2) {
              this.expireToken = true;
            }
            else{
              if (response.data.step_admission === "step6")
              {
                this.$router.push('confirmation-process');
                this.step.firstStep = false;
              }
              else{
    //            this.$refs.form1.submit()
              }
            }
            })
            .catch(function (error) {
              console.log(error);
            });
          }
      }
    },

    backStep() {
      this.$router.push('recommendations-teamworks');
      this.step.firstStep = false;
    },
    modalErrorClose()
    {this.serviceError=false},
    closeModalPaymentKO(param) {
      const modalExpireToken = document.querySelector('#payment_ko');
      modalExpireToken.style.display = 'none';
      if (param)
       {window.location.href = "mailto:info@theglobalcollege.com";}
    }
  }
}
</script>
